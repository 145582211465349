@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Spinner styles */
.spinner {
  border: 4px solid rgba(255, 255, 255, 1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: black;
  animation: spin 1s ease infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

